import {ServicesSlider, ServiceOffered} from '../../organisms';

const ServicesPage = () => {

    return (
        <>
            <ServicesSlider/>
            <ServiceOffered/>
        </>
    )
}

export default ServicesPage;