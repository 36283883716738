import {PricingSlider, PricingPlan} from '../../organisms';

const PricePage = () => {

    return (
        <>
            <PricingSlider/>
            <PricingPlan/>
        </>
    )
}

export default PricePage;