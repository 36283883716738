export const reviews = [
    { 
      id: 1, 
      text: "I recently visited this salon and had a great  experience! My stylist was talented and attentive. They listened carefully to my needs and delivered exactly what I wanted. The salon itself is modern, and stylishly decorated. The equipment and products used are top-notch.", 
      author: "Yashar Arafath", 
      date: "" 
    },
    { 
      id: 2, 
      text: "Nice service..have multiple combos with decent prizes. Had good professionals for hair cut.", 
      author: "Malli Rajan", 
      date: ""  
    },
    { 
      id: 3, 
      text: "I went there for the first time and got my hair done and I'm very satisfied. Thanks to the hairstylist who beautified me.", 
      author: "Rajesh kumar", 
      date: ""  
    },
    { 
      id: 4, 
      text: "Great experience at the socio barber. The staff is friendly, skilled, and attentive to detail. The atmosphere is clean and welcoming. Got exactly the haircut I wanted. Highly recommend!", 
      author: "Joseph dion", 
      date: ""  
    },
    { 
      id: 5, 
      text: "Amazing ambiance and professional staff ❤️ would definitely recommend for people from all walks of life. Premium services at very affordable prices.", 
      author: "Niveetha s", 
      date: ""  
    },
    { 
      id: 6, 
      text: "If you have high budget, and for good haircut there are places for that and if you are on tight budget and ready to sacrifice on experience there are places for it and then there's sociobarber balances both budget and quality🙂😁. If you prefer a good customer interaction they have that too.", 
      author: "Mani Kandan G", 
      date: ""  
    },
    { 
      id: 7, 
      text: "Excellent service and great customer care . Well maintained saloon. Keep it up . Special regards to Sandeep . Best service", 
      author: "Sivakumaran S K", 
      date: ""  
    },
    { 
      id: 8, 
      text: "Was near experience and the stylish suggest some good routine to follow for hair care. Since I had a hair fall issue took their hair spa treatment will update the results after 3 more sitting here. So far am happy and would suggest this place.", 
      author: "Naveed Ahamed", 
      date: ""  
    },
    { 
      id: 9, 
      text: "Had a service at this salon after a very long time I feel myself had a great service Interior was so good and calm u recommend people to take service here Thank you team", 
      author: "Deepak R", 
      date: ""  
    },
  ];